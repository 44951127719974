var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"chartContainer",staticClass:"f-wh inner-chart",staticStyle:{"overflow":"hidden","position":"relative"},attrs:{"id":"chartContainer"}},[(_vm.getLayout !== '1tab')?_c('CustomImageFullScreen',{staticClass:"full-1",staticStyle:{"z-index":"1","background-color":"lightgray","position":"absolute","right":"90px","top":"5px"},attrs:{"tooltipText":!_vm.isFullScreen ? 'Full Screen':'Split Screen',"tooltipPosition":"bottom center"},on:{"click":_vm.onClickFullScreen}}):_vm._e(),_c('ChartUpd',{ref:"chartWrapper",attrs:{"data":(_vm.isTrainingMode && _vm.selectedTab == _vm.chartIndex) ? _vm.trainingModeDataRaw : _vm.multiOhlcv[_vm.chartIndex],"titleTxt":_vm.symbolName(_vm.chartIndex),"colorBack":_vm.colors.back,"colorGrid":_vm.colors.grid,"colorText":_vm.themeFontColor.color,"colorTitle":_vm.themeFontColor.color,"colorWickUp":_vm.settings.colorWickUp,"colorWickDw":_vm.settings.colorWickDw,"colorVolDw":_vm.settings.colorVolDw,"roundOffVolume":_vm.roundOffVolume,"colorCandleDw":_vm.generalSettingSet('chart', 'downColor', 'colorCandleDw'),"colorCandleUp":_vm.generalSettingSet('chart', 'upColor', 'colorCandleUp'),"colorCross":_vm.generalCrossHairSettingSet(
      'crossHair',
      'lineColor',
      'colorCrossHairLine'
    ),"colorTextHL":_vm.generalCrossHairSettingSet(
        'crossHair',
        'textColor',
        'colorCrossHairText'
      ),"colorPanel":_vm.generalCrossHairSettingSet('crossHair', 'boxColor', 'colorCrossHairBox'),"colorVolUp":"#FF0039","enableCrosshair":true,"indexBased":true,"ignoreNegativeIndex":true,"extensions":_vm.extensions,"overlays":_vm.overlays,"height":_vm.chartHeightTemp,"width":_vm.chartWidthTemp,"xsett":_vm.xsett,"chartIndex":_vm.chartIndex},on:{"legend-button-click":_vm.on_button_click,"ChartLoad":_vm.ChartLoad,"chartUpdate":_vm.chartUpdate,"updatChartRange":_vm.updatChartRange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }