<template>
    <div v-if="isFullScreen && getLayout !== '3tab_2' && getLayout !== '3tab_3'" class="left-container full" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <div>
        <ChartContainer :chartIndex="fullScreenIndex" :chartHeightTemp="chartHeight" :chartWidthTemp="chartWidth"
            :chartLayout="'1tab'" />
        </div>
    </div>
    <div v-else-if="getLayout == '1tab' && !isFullScreen" class="left-container" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <ChartContainer :chartIndex="0" :chartHeightTemp="chartHeight" :chartWidthTemp="chartWidth"
            :chartLayout="'1tab'" />
    </div>
    <div v-else-if="getLayout == '2tab_1' && !isFullScreen" class="left-container" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <ChartContainer :chartIndex="0" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="chartWidth - 4"
            :chartLayout="'2tab_1'" />
        <ChartContainer :chartIndex="1" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="chartWidth - 4"
            :chartLayout="'2tab_1'" />
    </div>
    <div v-else-if="getLayout == '2tab_2' && !isFullScreen" class="left-container" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <div class="left-container-horizontal">
            <ChartContainer :chartIndex="0" :chartHeightTemp="chartHeight" :chartWidthTemp="(chartWidth / 2)-2"
                :chartLayout="'2tab_2'" />
            <ChartContainer :chartIndex="1" :chartHeightTemp="chartHeight" :chartWidthTemp="(chartWidth / 2)-2"
                :chartLayout="'2tab_2'" />
        </div>


    </div>
    <div v-else-if="getLayout == '3tab_1' && !isFullScreen" class="left-container" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <ChartContainer :chartIndex="0" :chartHeightTemp="(chartHeight / 3)" :chartWidthTemp="chartWidth - 2"
            :chartLayout="'3tab_1'" />
        <ChartContainer :chartIndex="1" :chartHeightTemp="(chartHeight / 3)" :chartWidthTemp="chartWidth - 2"
            :chartLayout="'3tab_1'" />
        <ChartContainer :chartIndex="2" :chartHeightTemp="(chartHeight / 3)" :chartWidthTemp="chartWidth - 2"
            :chartLayout="'3tab_1'" />

    </div>
    <div v-else-if="getLayout == '3tab_2' && !isFullScreen" class="left-container " id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <div>
            <div class="left-container-horizontal">
                <ChartContainer :chartIndex="0" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="(chartWidth / 2) - 2" :chartLayout="'3tab_2'" />
                <ChartContainer :chartIndex="1" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="(chartWidth / 2) - 2" :chartLayout="'3tab_2'" />
            </div>
            <ChartContainer class="asasa" :chartIndex="2" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="chartWidth - 4" :chartLayout="'3tab_2'" />
        </div>
        
   

    </div>
    <div v-else-if="getLayout == '3tab_3' && !isFullScreen" class="left-container " id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <div>
            <ChartContainer class="asasa" :chartIndex="0" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="chartWidth - 4" :chartLayout="'3tab_3'" />
            <div class="left-container-horizontal">
                <ChartContainer :chartIndex="1" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="(chartWidth / 2) - 2" :chartLayout="'3tab_3'" />
                <ChartContainer :chartIndex="2" :chartHeightTemp="chartHeight / 2" :chartWidthTemp="(chartWidth / 2) - 2" :chartLayout="'3tab_3'" />
            </div>
        </div>
        
   

    </div>
    <div v-else-if="getLayout == '4tab' && !isFullScreen" class="left-container" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <ChartContainer :chartIndex="0" :chartHeightTemp="(chartHeight / 2) - 4" :chartWidthTemp="(chartWidth / 2) - 4" :chartLayout="'4tab'" />
        <ChartContainer :chartIndex="1" :chartHeightTemp="(chartHeight / 2) - 4" :chartWidthTemp="(chartWidth / 2) - 4" :chartLayout="'4tab'" />
        <ChartContainer :chartIndex="2" :chartHeightTemp="(chartHeight / 2) - 4" :chartWidthTemp="(chartWidth / 2) - 4" :chartLayout="'4tab'" />
        <ChartContainer :chartIndex="3" :chartHeightTemp="(chartHeight / 2) - 4" :chartWidthTemp="(chartWidth / 2) - 4" :chartLayout="'4tab'" />

    </div>
    <div v-else class="left-container" id="left-container" :style="themePrimaryColor"
        ref="chartContainerLayRef">
        <ChartContainer :chartIndex="fullScreenIndex" :chartHeightTemp="chartHeight" :chartWidthTemp="chartWidth"/>
    </div>
</template>

<script>

import Chart from './Chart.vue';
import Loader from 'BaseComponents/Loader'
import { mapGetters, mapActions } from 'vuex'
import RightToolBox from './RightToolBox.vue'
import ChartContainer from './ChartContainer.vue';
export default {
    name: 'LayoutChart',
    components: {
        Chart,
        Loader,
        RightToolBox,
        ChartContainer
    },
    data() {
        return {
            chartHeight: 0,
            chartWidth: 0,
        };
    },
    computed: {
        ...mapGetters([
            'themePrimaryColor',
            'getLayout',
            'getTabCount',
            'fullScreenIndex',
            'isFullScreen'
        ]),
        chartContainerRef() {
            return this.$refs.chartContainerLayRef
        },
    },
    methods: {
        updateChartDimensions() {
            if (this.chartContainerRef) {
                this.chartHeight = this.chartContainerRef.clientHeight;
                this.chartWidth = this.chartContainerRef.clientWidth;
            }
        },
    },
    watch: {
        getLayout() {
            this.$store.commit('SET_CHART_LOADING_STATUS', true)
            setTimeout(() => {
                this.$store.commit('SET_CHART_LOADING_STATUS', false)
            }, 5000);
        },
        getTabCount(newVal, oldVal) {
            if (oldVal > newVal) {
                this.$store.commit('SET_SELECTED_TAB', newVal - 1)
            }
        }

    },
    mounted() {
        this.updateChartDimensions();
        window.addEventListener('resize', this.updateChartDimensions);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateChartDimensions);
    },

}
</script>

<style>
.left-container-horizontal {
    display: flex;
}

.chart-tab {
    border: 1px solid #D3D3D3;
}

.selected-tab {
    border: 1px solid blue;
}
</style>