<template>
  <dialog-box size="mini" modalHeader="Delete Layout" :open="open" :action="false">
    <sui-form :loading="showLoader" size="small">
      <div class="ui center aligned">
        <h4 class="ui">
          Are you sure you want to remove your
          <span style="color: red">({{ deleteLayoutName }})</span> Layout Template?
        </h4>
        <br />
      </div>
    </sui-form>
    <template v-slot:action>
      <sui-modal-actions>
        <div>
          <sui-button negative @click.native="removeLayout">Yes </sui-button>
          <sui-button @click.native="$emit('closePopup')">No
          </sui-button>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogBox from '@/baseComponents/DialogBox.vue'
export default {
  name: 'WatchlistScanPopup',
  components: { DialogBox },
  model: {
    prop: 'open',
    event: 'change',
  },
  computed: {
    ...mapGetters([
      'themeSecondaryColor',
      'themeDarkMode',
    ]),
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    deleteLayoutName: {
      type: String
    },
    deleteLayoutId: {
      type: String
    }

  },
  data() {
    return {
      showLoader: false,
    }
  },
  methods: {
    ...mapActions([
      'deleteLayout'
    ]),
    async removeLayout() {
      this.showLoader = true
      this.deleteLayout(this.deleteLayoutId)
      setTimeout(() => {
        this.showLoader = false
        this.$emit('closePopup')
      }, 1000)

    }
  },

}
</script>

<style scoped>
.Favourite-Popup {
  height: 200px;
  width: 350px;
  box-shadow: 0px 0.2px 20px 2px #afa7a7;
  z-index: 106;
  background-color: #ffffff;
  position: relative;
  top: 10px;
}

.popup-firstChild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;

  padding-bottom: 10px;
  box-sizing: border-box;
}

.popup-secondChild {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 40%;
  width: 100%;
  padding: 8px 13px;
  gap: 4px;
}

input {
  width: 80%;
  height: 30%;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0.2px 1.2px 1.5px #dddddd;
}
</style>
