var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-box',{staticStyle:{"z-index":"110"},attrs:{"size":"mini","modalHeader":"Save Layout","open":_vm.open,"action":false},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('sui-modal-actions',{style:(_vm.themeDarkMode
      ? {
        background: '#151515 !important',
        borderColor: '#151515',
        color: '#A2A2A2',
      }
      : {})},[(_vm.getLayout !== '1tab')?_c('div',[_c('sui-button',{style:(_vm.themeSecondaryColor),attrs:{"negative":"","disabled":!(_vm.layoutName && _vm.layoutName.trim()) || _vm.errorMsg.length > 0},nativeOn:{"click":function($event){return _vm.addLayout.apply(null, arguments)}}},[_vm._v("Save ")]),_c('sui-button',{style:(_vm.themeDarkMode
          ? {
            background: '#505050 !important',
            borderColor: '#151515',
            color: '#A2A2A2',
          }
          : {}),attrs:{"disabled":_vm.showLoader},nativeOn:{"click":function($event){return _vm.toggleWatchesModalInner.apply(null, arguments)}}},[_vm._v("Cancel ")])],1):_c('div',[_c('sui-button',{style:(_vm.themeDarkMode
          ? {
            background: '#505050 !important',
            borderColor: '#151515',
            color: '#A2A2A2',
          }
          : {}),attrs:{"disabled":_vm.showLoader},nativeOn:{"click":function($event){return _vm.toggleWatchesModalInner.apply(null, arguments)}}},[_vm._v("Ok ")])],1)])]},proxy:true}])},[_c('sui-form',{attrs:{"loading":_vm.showLoader}},[(_vm.getLayout !== '1tab')?_c('div',{staticClass:"ui center aligned header positionStyle fontStyle"},[_c('h4',{staticClass:"ui header fontStyle",staticStyle:{"font-size":"14px"},style:(_vm.themeDarkMode ? { color: '#A2A2A2' } : {})},[_vm._v(" Please enter a name for saving layout: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.layoutName),expression:"layoutName"}],style:(_vm.themeDarkMode
        ? {
          background: '#505050 !important',
          borderColor: '#151515',
          color: '#A2A2A2',
        }
        : {}),attrs:{"type":"text"},domProps:{"value":(_vm.layoutName)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addLayout.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.layoutName=$event.target.value}}}),(_vm.errorMsg)?[_c('h6',{staticClass:"ui red",staticStyle:{"font-size":"14px","color":"red"},style:(_vm.themeDarkMode ? { color: '#A2A2A2' } : {})},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])]:_vm._e(),(_vm.resError)?[_c('h6',{staticClass:"ui red",staticStyle:{"font-size":"14px","color":"red"},style:(_vm.themeDarkMode ? { color: '#A2A2A2' } : {})},[_vm._v(" "+_vm._s(_vm.resError)+" ")])]:_vm._e()],2):_c('div',[_c('h4',[_vm._v("Please use multi screen to save template.")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }