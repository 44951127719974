<template>
  <dialog-box :modalHeader="modelHeader" :open="open" class="fontSize" v-model="open" @onClose="onCancel" @ok="onSave">

    <div style="display: flex;">
      <div>
        <div class="input-container fontSize" v-if="isPnf">
          <span> Box size </span>
          <input style="width: 10ch" type="number" min="0.1" step="0.01" v-model="boxSize" />
        </div>
        <div class="input-container fontSize" v-if="isPnf">
          <span> Reversal </span>
          <input style="width: 10ch" type="number" min="1" step="1" v-model="reversal" />
        </div>
        <sui-form-fields>
          <sui-form-field style="margin-bottom:10px">
            <sui-input v-model="maxPrice" type="number" tabindex="1" />
            Max price scale
          </sui-form-field>
          <sui-form-field style="margin-bottom:10px">
            <sui-input v-model="minPrice" type="number" tabindex="1" />
            Min price scale
          </sui-form-field>
        </sui-form-fields>
        <sui-button style="margin-bottom:10px;" @click.prevent="handleResetScale()" size="mini">
          Set Default Scale
        </sui-button>
      </div>
      <div>
        <div class="input-container fontSize" style="margin-left: 100px; flex-direction: column; align-items: flex-start;">
        <p><b>Chart Type</b></p>
        <sui-form-field>
          <input type="radio" id="logScale_settings" name="logScale" value="logScale"
            :style="{ cursor: 'pointer', marginRight: '.6em', marginBottom:'.6em' }" v-model="scale" />
          <label for="logScale_settings" :style="getRadioProps">Log Chart</label>
        </sui-form-field>
        <sui-form-field>
          <input type="radio" id="stdScale_settings" name="logScale" value="stdScale"
            :style="{ cursor: 'pointer', marginRight: '.6em', marginBottom:'.6em' }" v-model="scale" />
          <label for="stdScale_settings" :style="getRadioProps">Standard Chart</label>
        </sui-form-field>
      </div>
    </div>
    </div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { chartEvents } from '../../chartEvents'
export default {
  components: { DialogBox },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'MainChartProps',
  data() {
    return {
      isLoading: false,
      maxPrice: 0,
      minPrice: 0,
      boxSize: 0,
      reversal: 2,
      scale: 'stdScale'
    }
  },
  methods: {
    ...mapActions(['setLogScale']),
    ...mapMutations(['MAIN_CHART_PROPS']),
    onCancel() {
      this.MAIN_CHART_PROPS(!open)
    },
    handleResetScale() {
      this.multiTv.map((tv) => {
        tv?.data?.tv.toggleSideBarYAxis()
      })
      this.minPrice = 0;
      this.maxPrice = 0;
      this.MAIN_CHART_PROPS(!open)
    },
    onSave() {
      if (this.isPnf) {
        chartEvents.$emit('PnfValueUpdate', {
          boxSize: this.boxSize,
          reversal: this.reversal,
        })
      }
      if (this.scale == 'logScale') {
        this.setLogScale(true)
      }
      else {
        this.setLogScale(false)
      }
      if (this.maxPrice != 0 && this.minPrice != 0) {
        this.multiTv.map((tv) => {
          tv?.data?.tv.toggleSidebarCustomRange([this.maxPrice, this.minPrice])
        })
      }
      this.MAIN_CHART_PROPS(!open)
    },
    PnfValueSetterToModal(val) {
      this.boxSize = val.boxSize
      this.reversal = val.reversal
    },
  },
  computed: {
    ...mapGetters(['getChartType', 'multiTv', 'logScale', 'themeFontColor']),
    getRadioProps() {
      return {
        ...this.themeFontColor,
        cursor: 'pointer',
        marginRight: '.8em',
        fontWeight: 'normal',
      }
    },
    modelHeader() {
      return `Chart Settings ${this.getChartType}`
    },
    isPnf() {
      return this.getChartType === 'PnF'
    },
    isCandles() {
      return this.getChartType === 'Candles'
    },
  },
  mounted() {
    chartEvents.$on('PnfValueSetterToModal', this.PnfValueSetterToModal)
  },
  beforeDestroy() {
    chartEvents.$off('PnfValueSetterToModal')
  },
  watch: {
    logScale(newVal, oldVal) {
      if (newVal) {
        this.scale = 'logScale'
      }
      else {
        this.scale = 'stdScale'
      }
    }
  }
}
</script>

<style>
.input-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 15px;
}
.input-container>* {
  margin-right: 0.3rem;
}
</style>
