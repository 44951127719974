<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      /* padding-left: 1px; */
      /* padding: 0.1rem; */

      /* box-sizing: border-box; */
      gap: 50px;
      /* background-color: red; */
    "
    :style="themePrimaryColor"
  >
    <!-- class="f-wh" -->
    <div class="top-container">
      <top-toolbox :style="themePrimaryColor"></top-toolbox>
    </div>
    <div class="middle-container f-w" id="middle-container">
      <LayoutChart/>
      <div class="right-container" id="right-container" :style="themeDarkMode
            ? { 'border-left': '1px solid #2C2929' }
            : { 'border-left': '1px solid #eee' }
            ">
            <right-tool-box />
        </div>
    </div>
    

    <bottom-toolbox style="z-index:101" ></bottom-toolbox>
  </div>
</template>

<script>
import Chart from './Chart'
import TopToolbox from './TopToolbox.vue'
import BottomToolbox from './BottomToolbox.vue'
import RightToolBox from './RightToolBox.vue'
import Loader from 'BaseComponents/Loader'
import { mapGetters, mapActions } from 'vuex'
import {
  isLogged,
  fetchAllSubscriptions,
  isClientAccountActive,
} from '../services/user'
import { setJwt } from '../services/http'
import LayoutChart from './LayoutChart.vue'

export default {
  name: 'HomeComponent',
  components: {
    Chart,
    TopToolbox,
    BottomToolbox,
    RightToolBox,
    Loader,
    LayoutChart
  },

  async mounted() {
    // this.$router
    this.$store.commit('SET_MINUS_INDEX',this.$route.query?.i ?? 0)
    const response = await this.$store.dispatch(
      'user/retrieveUserSubscriptionStatus'
    )
    if (response) {
      await isLogged()
      setJwt()
      setInterval(() => {
        
        isClientAccountActive().then((d) => {
          
          if (d.status === 'inactive') {
            this.$router.push('/products')
          }
        })
      }, 60000 * 30)
      this.retrieveSymbolsList()
    } else {
      this.$router.push('/products')
    }
  },

  methods: {
    ...mapActions(['retrieveSymbolsList', 'showBrushData']),
  },
  computed: {
    ...mapGetters([
      'getSymbolsList',
      'isChartLoading',
      'themeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      ,
      'themeDarkMode',
      'themeFontColor',
    ]),
    showChart() {
      return this.getSymbolsList.length > 0
    },

    // togglebox(){
    //   return !this.togglerighttoolbox ? 'background-color: red' : ''
    //     //  return `@media screen and (max-width: 1000px) { .right-container {display : none}  }`
    // },

    showLoader() {
      let showLoad = !this.showChart || this.isChartLoading
      if (!showLoad) {
        // this.showBrushData()
      }
      return showLoad
    },
  },
}
</script>
<style></style>
