<template>
  <sui-button
    icon
    secondary
    :negative="isToggled"
    @click="$emit('click')"
    :data-tooltip="tooltipText"
    :data-position="tooltipPosition"
    data-inverted=""
    class="full"
    
  >
    <sui-icon name="expand" style="color: black"/>
  </sui-button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'CustomImageFullScreen',
  props: {
    isToggled: {
      type: Boolean,
      default: false
    },
    tooltipPosition: {
      type: String,
      default: 'top center'
    },
    tooltipText: {
      type: String,
      default: null
    },
    toolColor :{
      type:String,
      default: " "
    }
    
  },
  computed : {
    ...mapGetters(['themeSecondaryColor',])
  }
}
</script>
