<template>
  <dialog-box id="mainForm"
    modalHeader="Update Profile Settings"
    :open="open"
    @onClose="onClose"
    @ok="submit"
  >
    <sui-modal-content :style="{...themeFontColor}">
      <sui-message v-if="showError" error>
        <sui-message-header>Action Forbidden</sui-message-header>
        <p>
          {{ errorMsg }}
        </p>
      </sui-message>
      <sui-message  v-if="showSuccess" success>
        <sui-message-header>Success</sui-message-header>
        <p>
          {{ errorMsg }}
        </p>
      </sui-message>
      <sui-modal-description>
        <sui-header :style="themeFontColor">{{ firstName }} {{ lastName }}</sui-header>
        <sui-form>


          <sui-form-field class="profile-font">
            <label :style="themeFontColor" >First Name</label>
            <sui-form-field>
              <input type="text" placeholder="First Name" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="firstName" />
            </sui-form-field>
          </sui-form-field>
        
        <sui-form-field class="profile-font">
            <label :style="themeFontColor">Last Name</label>
            <sui-form-field>
              <input type="text" placeholder="Last Name" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="lastName" />
            </sui-form-field>
          </sui-form-field>

          <sui-form-field class="profile-font">
            <label :style="themeFontColor">Email</label>
            <sui-form-field>
              <input type="email" placeholder="Email" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="email" readonly />
            </sui-form-field>
          </sui-form-field>

          <sui-form-field class="profile-font">
              <sui-button   @click.prevent="()=>showChangeEmailModel=true"> Change Email </sui-button>
          </sui-form-field>

          <change-email @change="showChangeEmailModel=false" :open="showChangeEmailModel">
          </change-email>

          <sui-form-field class="profile-font">
            <label :style="themeFontColor">Old Password</label>
            <sui-form-field>
              <input type="password" name="oldPassword" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="currentPassword" />
              
            </sui-form-field>
          </sui-form-field>

          <sui-form-field class="profile-font">
            <label :style="themeFontColor">New Password</label>
            <sui-form-field>
              <input type="password" name="newPassword" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}"
               v-model="newPassword" />
            </sui-form-field>
          </sui-form-field>

          <sui-form-field v-if="subs" class="profile-font">
            <label :style="themeFontColor"> Subscription Detail </label>
            <div v-for="(item, index) in subs" :keY="index">
              <div><b>Product Name: </b>{{ item.product?.desc?.title }}</div>
              <b>Period Start: </b> {{ formatDate(item.startPeriod) }} <b>Period End: </b
            >{{ formatDate(item.endPeriod) }}
              <div>
                <b> {{ trialModeText }} </b>
                <!-- <a
                    v-if="isTrialMode"
                    @click.prevent="onClickTrialActivate"
                    href="/"
                >Activate</a
                >

                <a v-else @click.prevent="onSubscriptionCancel" href="/"
                >Cancel</a
                > -->
                <span v-if="subs[0].subscription_cancel_status===null" style="color: red;">Subscription already cancelled.</span>
                <a v-else @click.prevent="onSubscriptionCancel" href="/">Cancel</a>
              </div>
            </div>
          </sui-form-field>

        </sui-form>
      </sui-modal-description>
    </sui-modal-content>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import {IndicatorSettingsDefaults} from '../../components/dialogBoxes/helper/GeneralSettingsDefaults'
import {
  getUserData,
  subscribeToPackage,
  deleteSubscription,
  fetchAllSubscriptions, updateUserProfile,
} from '../../services/user'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import ChangeEmail from './ChangeEmail.vue'

const notificationTimeout = 5000
export default {
  name: 'ProfileSettings',
  components: {
    DialogBox,
    ChangeEmail
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    const data = await getUserData();
    if(data.status==='active'){
    }else if(data.status==='inactive'){
      this.$router.push('/products')
    }
    else{
      this.$router.push('/login')
    }
    this.firstName = data.firstName
    this.userId = data.id
    this.lastName = data.lastName
    this.email = data.email
    this.subscription = data.subscription || 'yearly'
    this.currentPassword = ''
    if (data && data.userData) {
      let newDataSet = JSON.parse(JSON.stringify(data.userData))
      if(!("additional" in newDataSet?.indicatorSettings)){
        newDataSet.indicatorSettings['additional'] = IndicatorSettingsDefaults?.indicatorSettings?.additional 
      }
      await this.updateProfileData(newDataSet)
      this.$store.commit('SET_USER_EMAIL', this.email)
    }
    if (data && data.id) {
      this.$store.commit('SET_USER_ID', data.id)
      this.getUserWatches()
      this.getUserScanList()
      this.getSavedLayouts()
      this.getSPXDataFunc()
    }
    
    if (data && data.userSettings && data.userSettings.chartSettings) {
      this.$store.commit('SET_CHART_SETTINGS', data.userSettings.chartSettings)

      this.$store.commit(
          'SET_CHART_DEFAULT_SETTINGS',
          data.userSettings.chartSettings
      )
      this.$store.commit('UPDATE_CHART_STATE', new Date().getTime())
    }
    
  },
  methods: {
    ...mapActions(['getUserWatches', 'getUserScanList', 'updateProfileData', 'getSavedLayouts', 'getSPXDataFunc']),
    onClose() {
      this.$emit('change', !open)
    },
    setError(msg){
      this.errorMsg = msg
      this.showError = true
      this.showSuccess = false
      setTimeout(() => {
        this.showError = false
        this.showSuccess = false
        this.errorMsg = ""
      },notificationTimeout)
    },
    setSuccess(msg){
      this.currentPassword = ""
      this.newPassword = ""
      this.errorMsg = msg
      this.showError = false
      this.showSuccess = true
      setTimeout(() => {
        this.showError = false
        this.showSuccess = false
        this.errorMsg = ""
      },notificationTimeout)
    },
    submit() {
      let updateProfile = {
        firstName:this.firstName,
        lastName:this.lastName,
        email:this.email,
        userId:this.userId,
        currentPassword:this.currentPassword,
        newPassword:this.newPassword,
      }
      updateUserProfile(updateProfile).then(d => {
        if(d?.status){
          this.setSuccess(d.msg)
        }else{
          this.setError(d.msg)
        }
      }).catch(e => {
        this.setError('Invalid Request')
      })
    },
    formatDate(val){
      return moment(val).format("DD/MM/YYYY HH:mm")
    },
    async onClickTrialActivate() {
      this.$router.push("/products")
      // const url = await subscribeToPackage(this.subscription)
      // if(!url.error){
      //   window.location = url?.data
      // }else{
      //   alert("something went wrong")
      // }
    },
    async onSubscriptionCancel() {
      if(confirm('Are you sure, you want to cancel the subscription?')){
        const payload = this.subs[0].subscriptionId
        const cancel_response = await deleteSubscription(payload)
        // this.$router.push('/products')
        await this.$store.dispatch('user/retrieveUserSubscriptionStatus')
        this.$emit('change', !open);

        // localStorage.removeItem('state')
      }

      // location.reload()
      // const data = await fetchAllSubscriptions()
      // this.$store.commit('user/setSubscriptionList', data.subscription)
    },
//     EscBtn:document. addEventListener('keyup', function(event){
// if(event. key === "Escape"){
// document.getElementById('...mainForm').modal('hide');
// }
// })
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      userId: '',
      currentPassword: '',
      newPassword: '',
      subscription: '',
      isTrial: false,
      showError: false,
      showSuccess: false,
      showChangeEmailModel:false,
      errorMsg: "",
      subs: [],
    }
  },
  computed: {
    ...mapGetters(['themeFontColor', 'themeDarkMode','themeSecondaryColor']),
    subscriptions() {
      return this.$store.state.user.subscriptionList
    },
    isTrialMode() {
      return this.$store.state.user.subscriptionStatus === 'trialing'
    },
    trialModeText() {
      return this.isTrialMode
          ? ' Trial ends in ' + this.$store.state.user.trialEndsIn + ' day(s)'
          : ''
    }
  },
  watch: {
    subscriptions() {
      this.subs = this.subscriptions
    },
  },
  beforeUpdate(){
  }
  
}
</script>

<style>
.profile-font{
  font-size: 14px;
}
</style>
