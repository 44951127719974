<template>
  <dialog-box size="mini" modalHeader="Save Layout" :open="open" :action="false" style="z-index: 110">

    <sui-form :loading="showLoader">

      <div class="ui center aligned header positionStyle fontStyle " v-if="getLayout !== '1tab'">
        <h4 :style="themeDarkMode ? { color: '#A2A2A2' } : {}" class="ui header fontStyle" style="font-size: 14px">
          Please enter a name for saving layout:
        </h4>

        <input type="text" v-model="layoutName" @keypress.enter="addLayout" :style="themeDarkMode
          ? {
            background: '#505050 !important',
            borderColor: '#151515',
            color: '#A2A2A2',
          }
          : {}
          " />
        <template v-if="errorMsg">
          <h6 class="ui red" style="font-size: 14px; color: red;" :style="themeDarkMode ? { color: '#A2A2A2' } : {}">
            {{ errorMsg }}
          </h6>
        </template>
        <template v-if="resError">
          <h6 class="ui red" style="font-size: 14px; color: red;" :style="themeDarkMode ? { color: '#A2A2A2' } : {}">
            {{ resError }}
          </h6>
        </template>

      </div>
      <div v-else>
        <h4>Please use multi screen to save template.</h4>
      </div>

    </sui-form>
    <template v-slot:action>
      <sui-modal-actions :style="themeDarkMode
        ? {
          background: '#151515 !important',
          borderColor: '#151515',
          color: '#A2A2A2',
        }
        : {}
        ">
        <div v-if="getLayout !== '1tab'">
          <sui-button negative :disabled="!(layoutName && layoutName.trim()) || errorMsg.length > 0"
            @click.native="addLayout" :style="themeSecondaryColor">Save
          </sui-button>
          <sui-button :disabled="showLoader" @click.native="toggleWatchesModalInner" :style="themeDarkMode
            ? {
              background: '#505050 !important',
              borderColor: '#151515',
              color: '#A2A2A2',
            }
            : {}
            ">Cancel
          </sui-button>
        </div>
        <div v-else>
          <sui-button :disabled="showLoader" @click.native="toggleWatchesModalInner" :style="themeDarkMode
            ? {
              background: '#505050 !important',
              borderColor: '#151515',
              color: '#A2A2A2',
            }
            : {}
            ">Ok
          </sui-button>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogBox from '@/baseComponents/DialogBox.vue'
export default {
  name: 'WatchlistScanPopup',
  components: { DialogBox },
  model: {
    prop: 'open',
    event: 'change',
  },
  computed: {
    ...mapGetters([
      'themeSecondaryColor',
      'themeDarkMode',
      'getLayout'
    ]),
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      layoutName: '',
      showLoader: false,
      errorMsg: '',
      resError: ''
    }
  },
  methods: {
    ...mapActions([
      'saveLayout',
    ]),
    async addLayout(event) {
      event.preventDefault();
      this.showLoader = true
      if (!(this.layoutName && this.layoutName.trim())) return;
      if (this.layoutName.length) {
        let layoutName = this.layoutName
        const res = await this.saveLayout(layoutName)
        if (res?.isError) {
          this.layoutName = ""
          this.showLoader = false
          this.resError = res.data
        } else {
          this.layoutName = ""
          this.showLoader = false
          this.$store.commit('LAYOUT_POPUP', false)
        }
      }
    },
    toggleWatchesModalInner() {
      this.layoutName = ''
      this.$store.commit('LAYOUT_POPUP', false)
      // this.$emit('toggleWatchesModalInner')
    },
  },
  watch: {
    layoutName(n, o) {
      if (n.length > 8) {
        this.errorMsg = 'Name cannot exceed 8 characters.'
      }
      else {
        this.errorMsg = ''
      }
    }
  }
}
</script>

<style scoped>
.Favourite-Popup {
  height: 200px;
  width: 350px;
  box-shadow: 0px 0.2px 20px 2px #afa7a7;
  z-index: 106;
  background-color: #ffffff;
  position: relative;
  top: 10px;
}

.popup-firstChild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;

  padding-bottom: 10px;
  box-sizing: border-box;
}

.popup-secondChild {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 40%;
  width: 100%;
  padding: 8px 13px;
  gap: 4px;
}

input {
  width: 80%;
  height: 30%;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0.2px 1.2px 1.5px #dddddd;
}
</style>
