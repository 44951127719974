<template>
    <div :class="{
        'chart-container-tab': chartLayout === '4tab' && !isFullScreen,
        'chart-container': chartLayout !== '4tab' && !isFullScreen,
        'selected-tab': (selectedTab === chartIndex && chartLayout != '1tab' && !isFullScreen),
        'chart-tab': selectedTab !== chartIndex
    }" @click="!isTrainingMode && selectTab(chartIndex)">
        <loader v-if="showLoader" :showLoader="showLoader" />
        <chart v-if="showChart" :chartIndex="chartIndex" :chartHeightTemp="chartHeightTemp"
            :chartWidthTemp="chartWidthTemp" :chartSymbolTitle="chartSymbolTitle" :chartOHLCV="chartOHLCV"></chart>
    </div>
</template>

<script>

import Chart from './Chart.vue';
import Loader from 'BaseComponents/Loader'
import { mapGetters, mapActions } from 'vuex'
import RightToolBox from './RightToolBox.vue'
export default {
    name: 'ChartContainer',
    components: {
        Chart,
        Loader,
        RightToolBox
    },
    data() {
        return {
            chartHeight: 0,
            chartWidth: 0,
            chartSymbolTitle: '',
            chartOHLCV: []
        };
    },
    props: {
        chartHeightTemp: {
            type: Number,
            required: true
        },
        chartWidthTemp: {
            type: Number,
            required: true
        },
        chartIndex: {
            type: Number,
            required: true
        },
        chartLayout: {
            type: String,
            required: true
        },

    },
    computed: {
        ...mapGetters([
            'getSymbolsList',
            'selectedTab',
            'multiChartSymbol',
            'multiOhlcv',
            'isTrainingMode',
            'isFullScreen',
            'multiTv'
        ]),
        showChart() {
            return this.getSymbolsList.length > 0
        },
        showLoader() {
            let showLoad = !this.showChart || this.isChartLoading
            if (!showLoad) {
            }
            return showLoad
        },

    },
    methods: {
        selectTab(tabIndex) {
            if (this.selectedTab !== tabIndex) {
                this.$store.commit('SET_SELECTED_TAB', tabIndex)
            }
        },
    },
    watch: {
        multiChartSymbol(n, o) {
            this.chartSymbolTitle = n[this.chartIndex] ? n[this.chartIndex].symbol : ''
        },
        multiOhlcv(newVal, oldVal) {
            this.chartOHLCV = newVal[this.chartIndex]
        }
    },
}
</script>

<style>
.chart-tab {
    border: 1px solid #D3D3D3;
}

.selected-tab {
    border: 1px solid blue;
}
</style>