<template>
  <div class="symbol-search-container" :style="flexDirectionStyle" style="margin: auto;">
    <div class="search-container">
      <vue-autosuggest
        :isDisabled="isDisabled"
        ref="autoSuggestRef"
        class="right-header-search-combo"
        style="width: 105px"
        :suggestions="filteredOptions"
        :section-configs="sectionConfigs"
        :on-selected="onSelected"
        :input-props="inputProps"
        :renderSuggestion="renderSuggestion"
        @input="onInputChange"
        :value="value || selected"
      >
      </vue-autosuggest>
      <!-- v-on:keyup.enter="TopSymbol(value)" -->
      <!-- :get-suggestion-value="getSuggestionValue" -->
    </div>
    <!-- <input type="text" :value="value || selected" @click="selectAllText($event)"> -->
    <sui-button-group
      class="right attached right_attached"
      size="large"
      style="height: 100%; z-index: 1000"
    >
      <sui-button
        :style="fieldColor"
        :content="selectedSymbolType"
        class="right-header-market-button"
         :disabled="isDisabled"
      />

      <sui-dropdown
       :disabled="isDisabled"
        icon="caret down"
        class="button secondary mini right-header-mob-dropdn-button"
        style="display: none"
      >
        <sui-dropdown-menu
          :style="themePrimaryColor"
          style="
            /* z-index: 10000; */
            position: absolute;
            left: 0px;
            top: 33px;
            /* right: 10px; */
            color: black;
          "
        >
          <sui-dropdown-item
            style="font-size: 0.9em"
            :style="themeFontColor"
            @mouseover="enterThemehoverColor($event.target)"
            @mouseout="exitElementColor($event.target)"
            @click="selectSymbolModal"
          >
            Browse
          </sui-dropdown-item>
          <sui-dropdown-item
            style="font-size: 0.9em"
            :style="themeFontColor"
            @mouseover="enterThemehoverColor($event.target)"
            @mouseout="exitElementColor($event.target)"
            @click="selectPreviousSymbol"
          >
            Previous
          </sui-dropdown-item>
          <sui-dropdown-item
            style="font-size: 0.9em"
            :style="themeFontColor"
            @mouseover="enterThemehoverColor($event.target)"
            @mouseout="exitElementColor($event.target)"
            @click="selectNextSymbol"
            >Next
          </sui-dropdown-item>
        </sui-dropdown-menu>
      </sui-dropdown>

      <sui-button
        class="right-header-button"
        icon="caret down"
        secondary
         :disabled="isDisabled"
        @click="selectSymbolModal"
      />
      <sui-button
        class="right-header-button"
        icon="angle left"
        secondary
         :disabled="isDisabled"
        @click="selectPreviousSymbol"
      />
      <sui-button
        class="right-header-button"
        icon="angle right"
        secondary
         :disabled="isDisabled"
        @click="selectNextSymbol"
      />
      <sui-button
        class="openRightContainer"
        style="
          background-image: linear-gradient(#02a5de, #028fc0) !important ;
          border: none !important;
          width: 3rem;
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          justify-items: center;
          /* margin-left: -13px; */
        "
        :icon="arrowIcon"
        secondary
         :disabled="isDisabled"
        @click="Scroll()"
        v-if="showCloseButton == false"
      />

      <sui-button
        icon="x"
        secondary
         :disabled="isDisabled"
        @click="symbolName = ''"
        v-if="showCloseButton"
      />
    </sui-button-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueAutosuggest } from '../extensions/autoSuggest/vue-autosuggest'
import SearchSymbol from 'Components/dialogBoxes/SearchSymbol'
import { fetchSymbolByName } from '../services/symbol'
import { sectionConfig } from '@/constants/sectionConfig'
import Toolbox from './TopToolbox.vue'
import TopToolbox from './TopToolbox.vue'

export default {
  name: 'SymbolSearchBox',
  components: { VueAutosuggest, SearchSymbol, Toolbox, TopToolbox },
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
    },
    flexStart: {
      type: Boolean,
    },
    manualNavigation: {
      type: Boolean,
      default: true,
    },
    isDisabled:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      textInputEvent: null,
      textInputRef: null,
      optionDisplays: 'block',
      topSymbolSelect: '',
      arrowIcon: 'arrow left',
      symbolName: '',
      symbolIndex: 0,
      val: '',
      renderItem: '',
      currentSymbolType: '',
      selected: '',
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input' + Date.now().toString(),
        placeholder: 'search',
      },
      sectionConfigs: sectionConfig(this.onSelected),
      limit: 10,
    }
  },
  methods: {
    getSuggestionValue(suggestion) {
      let symbol = suggestion.item?.symbol
      let shortSymboName = ''
      if (symbol) {
        let symbolShortArray = symbol.replace(".au","").replace(".ca", "")
        if (symbolShortArray.length) {
          shortSymboName = symbolShortArray
        }
      }
      return shortSymboName
    },
    selectAllText(event) {
      // debugger
      // this.textInputRef = event.target.$options._parentVnode.data.ref
      this.textInputEvent = event
      event.target.select()
    },
    renderSuggestion(suggestion) {
      let currentItem = this.$refs.autoSuggestRef.$data?.currentItem
      let currentIndex = this.$refs.autoSuggestRef.$data?.currentIndex
      let selected = currentItem?.item === suggestion.item

      // this.value = currentItem?.item === suggestion.item
      let topSymbol = {
        label: this.filteredOptions[0].name.toUpperCase(),
        item: this.filteredOptions[0].data[0],
      }
      this.topSymbolSelect = topSymbol
      let topSymbolColor =
        !currentIndex && !currentItem?.item
          ? topSymbol.item == suggestion.item
          : ''
      let tagName = 'div'

      let styles = {
        backgroundColor: this.themeSecondaryColor.backgroundColor,
        color: 'white',
        display: 'flex',
        'align-items': 'center',
      }
      let stylesNull = {
        backgroundColor: 'transparent',
        display: 'flex',
        'align-items': 'center',
      }

      // debugger
      let stylesObj = selected
        ? { style: selected ? styles : stylesNull }
        : { style: topSymbolColor ? styles : stylesNull }
      let shortSymboName = suggestion.item.symbol.replace(".au","").replace(".ca", "")

      return this.$createElement(
        'div',
        {
          ...stylesObj,
        },
        [
          this.$createElement(
            'span',
            { style: { 'font-weight': 'bold', width: '60px' } },
            [
              this.$createElement(
                'span',
                { style: { 'font-weight': 'bold' } },
                shortSymboName
              ),
            ]
          ),
          this.$createElement(
            'span',
            {
              style: {
                width: 'max-content',
                'text-align': 'right',
                'flex-basis': '50%;',
              },
            },
            this.getCompanyName(suggestion.item)
          ),
        ]
      )
    },
    getCompanyName(symbol) {
      let res = ''
      let companyName = symbol.companyName
      if (companyName) {
        if (companyName.length > 30) {
          res = `${companyName.substring(0, 30)} ...`
        } else {
          res = companyName
        }
      }
      return res
    },
    Scroll() {
      if (this.scrollRight == true) {
        document.getElementById('right-container').style.display = 'block '
        document.getElementById('left-container').style.width = '50%'
        this.$store.commit('SCROLL_RIGHT', false)
        this.arrowIcon = 'arrow right'
      } else {
        document.getElementById('right-container').style.display = 'none '
        this.$store.commit('SCROLL_RIGHT', true)
        this.arrowIcon = 'arrow left'
      }
    },
    onSelected(option) {
      let optionalParams = option.item.symbol
      let optionalLabel = option.label
      this.selected = optionalParams
      // this.value = optionalParams
      this.currentSymbolType = optionalLabel
      this.symbolIndex = this.getSymbolsList.findIndex(
        (sym) => sym === optionalParams
      )
      this.$emit('input', optionalParams + '~' + optionalLabel)
      this.$emit('refreshInput', optionalParams + '~' + optionalLabel)
      this.selected = ''
    },

    handleSelect() {
    },

    TopSymbol() {
      let currentIndex = this.$refs.autoSuggestRef.$data?.currentIndex
      if (currentIndex == null) {
        this.onSelected(this.topSymbolSelect)
      }
    },

    async onInputChange(text) {
      if (!text) {
        let filtered2 = await fetchSymbolByName(this, '1')
        this.filteredOptions = filtered2
        this.$store.commit('TOP_LIST_SYMBOL', '1')
        this.$store.commit('TOP_LIST_SYMBOL_NAME', '1')
        this.optionDisplays = 'none'
        return
      } else {
        //TEMP_COMMENT
        let filtered2 = await fetchSymbolByName(this, text)
        this.optionDisplays = 'block'
        this.filteredOptions = filtered2
        this.$emit('TextMatchSymbolBox', this.filteredOptions.length, text)
        if (this.filteredOptions && this.filteredOptions.length) {
          this.$store.commit(
            'TOP_LIST_SYMBOL',
            JSON.stringify(filtered2[0].data[0].symbol)
          )
          this.$store.commit(
            'TOP_LIST_SYMBOL_NAME',
            JSON.stringify(filtered2[0].name)
          )
        } else {
          this.$store.commit('TOP_LIST_SYMBOL', '')
          this.$store.commit('TOP_LIST_SYMBOL_NAME', '')
          this.optionDisplays = 'none'
        }
      }
    },
    selectNextSymbol() {
      if (this.manualNavigation) {
        return this.$emit('nextSymbol')
      }

      if (this.getSymbolsList.length - 1 > this.symbolIndex) {
        this.symbolIndex++
        this.selected = this.getSymbolsList[this.symbolIndex]
        this.$emit('input', this.getSymbolsList[this.symbolIndex])
      }
    },
    selectPreviousSymbol() {
      if (this.manualNavigation) {
        return this.$emit('prevSymbol')
      }

      if (1 < this.symbolIndex) {
        this.symbolIndex--
        this.selected = this.getSymbolsList[this.symbolIndex]
        this.$emit('input', this.getSymbolsList[this.symbolIndex])
      }
    },
    selectSymbolModal() {
      return this.$emit('selectSymbolModal')
    },
  },

  computed: {
    ...mapGetters([
      'themeDarkMode',
      'dc',
      'tv',
      'getSymbolsList',
      'getMarketDesc',
      'topListSymbol',
      'topListSymbolName',
      'scrollRight',
      'themeFontColor',
      'themePrimaryColor',
      'themeSecondaryColor',
      'multiChartSymbol',
      'selectedTab'
    ]),
    fieldColor() {
      return this.themeDarkMode
        ? {
            background: '#707070 !important',
            borderColor: '#555555',
            color: '#C6C6C6',
            borderRadius: 0,
          }
        : {}
    },
    selectedSymbolType() {
      return this.multiChartSymbol[this.selectedTab]?.metaData?.exchange || 'ASX'
    },
    flexDirectionStyle() {
      const dir = this.flexStart ? 'flex-start' : 'flex-end'
      return `justify-content: ${dir};`
    },
  },
  watch: {
    // themeSecondaryColor(){
    //   this.hoverColorDrpd = this.themeSecondaryColor.backgroundColor
    // }
    //
  },
}
</script>

<style>
.symbol-search-container {
  display: flex;
  justify-content: flex-end;
}

#autosuggest {
  height: 100% !important;
}

#autosuggest div[role='combobox'] {
  height: 100%;
}

#autosuggest div[role='combobox'] input {
  height: 100%;
}

#autosuggest div[role='combobox'] input {
  /* box-shadow: 0px 0px 8px .5px rgb(88, 88, 179); */
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#autosuggest div[role='combobox'] input:focus {
  /* box-shadow: 0px 0px 8px .5px rgb(88, 88, 179); */

  border: 1px solid #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
.search-results {
  position: absolute;
  background-color: red;
  top: 100%;
  width: 100%;
  height: 500px;
  z-index: 200;
}

.n-input {
  padding: 0.8rem !important;
}

.search-container {
  position: relative;
  width: 42%;
}

.avatar {
  height: 25px;
  width: 25px;
  border-radius: 20px;
  margin-right: 10px;
}

#autosuggest div[role='combobox'] input {
  outline: none;
  position: relative;
  /* right: 15px; */
  display: block;
  border: 1px solid #e0dddd;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
@media screen and (max-width: 345px) {
  #autosuggest div[role='combobox'] input {
    left: 0.1rem;
  }
}

.autosuggest__results-container {
  position: relative;
  right: 25px;
  width: 229%;

  /* display: v-bind(optionDisplays); */
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  /* right: 0; */
  /* margin-left: 10px; */
  z-index: 10000001;
  min-width: 240px;
  width: max-content;
  max-width: inherit !important;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background: white;
  padding: 0px;
  max-height: 40vh;
  overflow-y: scroll;
  box-shadow: 0px 0px 15px 1px grey;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

/* .autosuggest__results ul .autosuggest__results-before{
  
} */

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: normal;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results-before {
  color: #000000;
  font-size: 11px;
  margin-left: 0;
  padding: 5px 10px;
  /* border-top: 1px solid lightgray; */
  font-weight: 400;
  background-color: #ebebeb;
  /* background-color: blue; */
  text-transform: uppercase;
}

.right-header-market-button {
  padding: 10px 10px !important;
  background-color: #f0f0f0 !important;
}

@media screen and (max-width: 560px) {
  .right-header-mob-dropdn-buttonn {
    display: block !important;
  }

  .right-header-button {
    display: none !important;
  }

  .right-header-mob-dropdn-button {
    display: flex !important;
    padding: 0.78571429em 0.78571429em !important;
    /* margin-right: 13px !important; */
    justify-content: center !important;
  }
}

@media screen and (max-width: 470px) {
  .right-header-button {
    display: none !important;
  }

  .autosuggest__results-container {
    position: relative;
    right: 0;
    width: 130%;

    /* display: v-bind(optionDisplays); */
  }

  .right-header-market-button {
    /* display:none !important; */
  }

  .right-header-search-combo {
    width: 160px !important;
  }
}

@media screen and (max-width: 420px) {
  .right-header-search-combo {
    width: 110px !important;
    margin-left: 5px;
  }
  .autosuggest__results-container {
    position: relative;
    right: 0;
    width: 160%;

    /* display: v-bind(optionDisplays); */
  }
}

@media screen and (max-width: 370px) {
  .right-header-market-button {
    font-size: 10px !important;
    /* display: none !important; */
  }

  .right-header-search-combo {
    width: 120px !important;
    margin-left: 25px;
  }
  .autosuggest__results-container {
    position: relative;
    right: 0;
    width: 130%;

    /* display: v-bind(optionDisplays); */
  }
}

/* @media screen and (max-width: 350px) {
    .right-header-search-combo{
      width: 70px !important;
    }
  }  */
.right_attached .ui.button > .icon:not(.button) {
  margin: 0 !important;
}

.autosuggest__results .autosuggest__results-item {
  padding: 0;
}

.autosuggest__results .autosuggest__results-item div {
  padding: 5px 10px;
}

.autosuggest__results::-webkit-scrollbar {
  display: none;
}

#autosuggest div[role='combobox'] input::selection {
  color: white !important;
  background: #3390ff !important;
}
</style>
